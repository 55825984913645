import AdvatagesCard from "../../AdvatagesCard/AdvatagesCard";
import AdvatagesCardItem from "../../AdvatagesCard/AdvatagesCardItem";
import AdvatagesCardItemIcon from "../../AdvatagesCard/AdvatagesCardItemIcon";
import AdvatagesCardItemTitle from "../../AdvatagesCard/AdvatagesCardItemTitle";
import CustomText from "../../utilities/CustomText/CustomText";
import SectionHead from "../../utilities/SectionHead/SectionHead";
import SectionHeadIcon from "../../utilities/SectionHead/SectionHeadIcon";
import SectionHeadTitlte from "../../utilities/SectionHead/SectionHeadTitlte";
import './Arsenal.scss'
import { useMediaQuery } from 'react-responsive'

function Arsenal() {
   const isShowSlider = useMediaQuery({ query: '(max-width: 1399px)' });
   return (
      <section className="arsenal">
         <SectionHead>
            <SectionHeadIcon>
               <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M37.5309 10.0691C37.4801 10.1426 37.422 10.2126 37.3566 10.278C35.5861 12.0493 35.5878 14.9164 37.3626 16.6925C38.9904 18.3148 41.5477 18.4391 43.318 17.0672L45.4592 14.9251C42.1444 14.4687 39.5205 11.8505 39.0594 8.5408L37.5309 10.0691ZM33.0974 14.5023L4.32871 43.2687C2.55819 45.0399 2.55996 47.907 4.33471 49.6831C6.10079 51.4433 8.96096 51.4401 10.7234 49.6756L39.4573 20.9295C37.9148 20.7037 36.4287 19.9984 35.2397 18.8134C34.0266 17.5994 33.3125 16.0778 33.0974 14.5023ZM46.0831 18.5456C46.0251 18.6372 45.9562 18.7238 45.8765 18.8037C45.6987 18.9816 45.5143 19.1487 45.324 19.3051L12.8486 51.7943C9.9152 54.7309 5.15393 54.7363 2.21179 51.804C-0.73453 48.8555 -0.737475 44.0922 2.20424 41.1493L41.1394 2.21728C41.1995 2.15712 41.2636 2.10314 41.3306 2.05532C42.6761 0.781428 44.4935 0 46.4937 0C50.6393 0 54 3.35691 54 7.49787C54 9.48173 53.2287 11.2856 51.9692 12.6267C51.9207 12.6952 51.8658 12.7605 51.8044 12.8219L46.0831 18.5456ZM46.4937 11.9966C48.981 11.9966 50.9975 9.98245 50.9975 7.49787C50.9975 5.0133 48.981 2.99915 46.4937 2.99915C44.0063 2.99915 41.9899 5.0133 41.9899 7.49787C41.9899 9.98245 44.0063 11.9966 46.4937 11.9966Z" fill="url(#paint0_linear_3032_756)" />
                  <path className="star star-el1" d="M19.3333 3.33239V1.66619C19.3333 0.745981 20.0795 0 21 0C21.9205 0 22.6667 0.745981 22.6667 1.66619V3.33239H24.3333C25.2538 3.33239 26 4.07837 26 4.99858C26 5.9188 25.2538 6.66478 24.3333 6.66478H22.6667V8.33097C22.6667 9.25118 21.9205 9.99716 21 9.99716C20.0795 9.99716 19.3333 9.25118 19.3333 8.33097V6.66478H17.6667C16.7462 6.66478 16 5.9188 16 4.99858C16 4.07837 16.7462 3.33239 17.6667 3.33239H19.3333Z" fill="url(#paint1_linear_3032_756)" />
                  <path className="star star-el2" d="M53.3333 29.3324V27.6662C53.3333 26.746 54.0795 26 55 26C55.9205 26 56.6667 26.746 56.6667 27.6662V29.3324H58.3333C59.2538 29.3324 60 30.0784 60 30.9986C60 31.9188 59.2538 32.6648 58.3333 32.6648H56.6667V34.331C56.6667 35.2512 55.9205 35.9972 55 35.9972C54.0795 35.9972 53.3333 35.2512 53.3333 34.331V32.6648H51.6667C50.7462 32.6648 50 31.9188 50 30.9986C50 30.0784 50.7462 29.3324 51.6667 29.3324H53.3333Z" fill="url(#paint2_linear_3032_756)" />
                  <path className="star star-el3" d="M39.3333 47.3324V45.6662C39.3333 44.746 40.0795 44 41 44C41.9205 44 42.6667 44.746 42.6667 45.6662V47.3324H44.3333C45.2538 47.3324 46 48.0784 46 48.9986C46 49.9188 45.2538 50.6648 44.3333 50.6648H42.6667V52.331C42.6667 53.2512 41.9205 53.9972 41 53.9972C40.0795 53.9972 39.3333 53.2512 39.3333 52.331V50.6648H37.6667C36.7462 50.6648 36 49.9188 36 48.9986C36 48.0784 36.7462 47.3324 37.6667 47.3324H39.3333Z" fill="url(#paint3_linear_3032_756)" />
                  <defs>
                     <linearGradient id="paint0_linear_3032_756" x1="27" y1="0" x2="27" y2="54" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5E5E5E" />
                        <stop offset="1" stopColor="#353535" />
                     </linearGradient>
                     <linearGradient id="paint1_linear_3032_756" x1="21" y1="0" x2="21" y2="9.99716" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5E5E5E" />
                        <stop offset="1" stopColor="#353535" />
                     </linearGradient>
                     <linearGradient id="paint2_linear_3032_756" x1="55" y1="26" x2="55" y2="35.9972" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5E5E5E" />
                        <stop offset="1" stopColor="#353535" />
                     </linearGradient>
                     <linearGradient id="paint3_linear_3032_756" x1="41" y1="44" x2="41" y2="53.9972" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5E5E5E" />
                        <stop offset="1" stopColor="#353535" />
                     </linearGradient>
                  </defs>
               </svg>

            </SectionHeadIcon>
            <SectionHeadTitlte className='white'>
               Арсенал решений Reffection
            </SectionHeadTitlte>
         </SectionHead>
         <div className="arsenal_card">
            <AdvatagesCard>
               <AdvatagesCardItem className="black" classCol='col-xl-3'>
                  <AdvatagesCardItemIcon>
                     <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                        <rect x="1" y="1" width="60" height="60" rx="12" stroke="#C9C9C9" />
                        <path d="M31 29.5C35.8462 29.5 41 28.0525 41 25.375V23.125C41 20.4475 35.8462 19 31 19C26.1538 19 21 20.4475 21 23.125V25.375C21 28.0525 26.1538 29.5 31 29.5ZM22.5385 23.125C22.5385 22.0525 25.8385 20.5 31 20.5C36.1615 20.5 39.4615 22.0525 39.4615 23.125V25.375C39.4615 26.4475 36.1615 28 31 28C25.8385 28 22.5385 26.4475 22.5385 25.375V23.125Z" fill="#C9C9C9" />
                        <path d="M31 43C35.8462 43 41 41.5525 41 38.875V36.625C41 36.4261 40.919 36.2353 40.7747 36.0947C40.6304 35.954 40.4348 35.875 40.2308 35.875C40.0268 35.875 39.8311 35.954 39.6868 36.0947C39.5426 36.2353 39.4615 36.4261 39.4615 36.625V38.875C39.4615 39.9475 36.1615 41.5 31 41.5C25.8385 41.5 22.5385 39.9475 22.5385 38.875V36.625C22.5385 36.4261 22.4574 36.2353 22.3132 36.0947C22.1689 35.954 21.9732 35.875 21.7692 35.875C21.5652 35.875 21.3696 35.954 21.2253 36.0947C21.081 36.2353 21 36.4261 21 36.625V38.875C21 41.5525 26.1538 43 31 43Z" fill="#C9C9C9" />
                        <path d="M31 36.25C35.8462 36.25 41 34.8025 41 32.125V29.875C41 29.6761 40.919 29.4853 40.7747 29.3447C40.6304 29.204 40.4348 29.125 40.2308 29.125C40.0268 29.125 39.8311 29.204 39.6868 29.3447C39.5426 29.4853 39.4615 29.6761 39.4615 29.875V32.125C39.4615 33.1975 36.1615 34.75 31 34.75C25.8385 34.75 22.5385 33.1975 22.5385 32.125V29.875C22.5385 29.6761 22.4574 29.4853 22.3132 29.3447C22.1689 29.204 21.9732 29.125 21.7692 29.125C21.5652 29.125 21.3696 29.204 21.2253 29.3447C21.081 29.4853 21 29.6761 21 29.875V32.125C21 34.8025 26.1538 36.25 31 36.25Z" fill="#C9C9C9" />
                     </svg>
                  </AdvatagesCardItemIcon>
                  <AdvatagesCardItemTitle className="white">
                     DMP (Data Management Platform)
                  </AdvatagesCardItemTitle>
                  <CustomText className="gray small">
                     Сегментирует собственную
                     Big Data с 50 млн анонимизированных актуальных портретов пользователей
                  </CustomText>
               </AdvatagesCardItem>
               <AdvatagesCardItem className="black" classCol='col-xl-3'>
                  <AdvatagesCardItemIcon>
                     <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                        <rect x="1" y="1" width="60" height="60" rx="12" stroke="#C9C9C9" />
                        <path d="M33.6813 21.0315C34.1732 21.1628 34.4652 21.6665 34.3334 22.1565L29.448 40.319C29.316 40.809 28.8105 41.0998 28.3186 40.9685C27.8266 40.8372 27.5347 40.3334 27.6665 39.8434L32.552 21.681C32.6838 21.191 33.1894 20.9002 33.6813 21.0315Z" fill="#C9C9C9" />
                        <path d="M36.4619 24.7699C36.8026 24.3928 37.3856 24.3623 37.7642 24.7016L39.9004 26.6168C40.8058 27.4284 41.5567 28.1017 42.0724 28.7122C42.6146 29.3543 43 30.0441 43 30.8956C43 31.7472 42.6146 32.4371 42.0724 33.0791C41.5567 33.6897 40.8058 34.363 39.9004 35.1746L37.7642 37.0897C37.3856 37.4291 36.8026 37.3985 36.4619 37.0215C36.1212 36.6444 36.152 36.0636 36.5305 35.7243L38.6162 33.8542C39.5849 32.9859 40.238 32.3975 40.6611 31.8965C41.0672 31.4157 41.1558 31.1351 41.1558 30.8956C41.1558 30.6563 41.0672 30.3756 40.6611 29.8948C40.238 29.3939 39.5849 28.8055 38.6162 27.9371L36.5305 26.0671C36.152 25.7278 36.1212 25.147 36.4619 24.7699Z" fill="#C9C9C9" />
                        <path d="M25.4695 26.0671C25.848 25.7278 25.8787 25.147 25.538 24.7699C25.1974 24.3928 24.6143 24.3623 24.2358 24.7016L22.0996 26.6168C21.1943 27.4284 20.4433 28.1017 19.9277 28.7122C19.3854 29.3543 19 30.0441 19 30.8956C19 31.7472 19.3854 32.4371 19.9277 33.0791C20.4433 33.6897 21.1943 34.363 22.0996 35.1745L24.2358 37.0897C24.6143 37.4291 25.1974 37.3985 25.538 37.0215C25.8787 36.6444 25.848 36.0636 25.4695 35.7243L23.3838 33.8542C22.4151 32.9859 21.762 32.3975 21.3389 31.8965C20.9329 31.4157 20.8442 31.1351 20.8442 30.8956C20.8442 30.6563 20.9329 30.3756 21.3389 29.8948C21.762 29.3939 22.4151 28.8055 23.3838 27.9371L25.4695 26.0671Z" fill="#C9C9C9" />
                     </svg>
                  </AdvatagesCardItemIcon>
                  <AdvatagesCardItemTitle className="white">
                     Функциональный код
                  </AdvatagesCardItemTitle>
                  <CustomText className="gray small">
                     Для идентификации и сбора поведенческих данных о скрытых пользователях, которые не регистрируются и не заполняют формы на сайтах                  </CustomText>
               </AdvatagesCardItem>
               <AdvatagesCardItem className="black" classCol='col-xl-3'>
                  <AdvatagesCardItemIcon>
                     <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                        <rect x="1" y="1" width="60" height="60" rx="12" stroke="#C9C9C9" />
                        <path d="M29.4286 19C26.8259 19 24.7143 21.15 24.7143 23.8C24.7143 26.45 26.8259 28.6 29.4286 28.6C32.0312 28.6 34.1429 26.45 34.1429 23.8C34.1429 21.15 32.0312 19 29.4286 19ZM27.0714 30.2C23.1551 30.2 20 33.4125 20 37.4V38.2C20 39.975 21.3996 41.4 23.1429 41.4H32.5714V39.8H27.8571V33.4H32.5714V30.2438C32.3136 30.2125 32.0497 30.2 31.7857 30.2H27.0714ZM34.1429 30.2V35H29.4286V38.2H34.1429V43H37.2857V38.2H42V35H37.2857V30.2H34.1429Z" fill="#C9C9C9" />
                     </svg>
                  </AdvatagesCardItemIcon>
                  <AdvatagesCardItemTitle className="white">
                     Рекламный кабинет
                  </AdvatagesCardItemTitle>
                  <CustomText className="gray small">
                     С доступом к контактным данным пользователей (телефон и e-mail), динамикой, статистикой и возможностью самостоятельного управления кампанией.                   </CustomText>
               </AdvatagesCardItem>
               <AdvatagesCardItem className="black" classCol='col-xl-3'>
                  <AdvatagesCardItemIcon>
                     <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                        <rect x="1" y="1" width="60" height="60" rx="12" stroke="#C9C9C9" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.31 20.0294C22.933 18.4591 25.6053 18.7386 26.9643 20.505L28.6455 22.6901C29.7513 24.1273 29.6537 26.136 28.3411 27.406L28.0227 27.714C28.0089 27.7532 27.9751 27.8768 28.0126 28.1115C28.0969 28.6401 28.5506 29.7615 30.4551 31.6041C32.359 33.4461 33.5195 33.8872 34.0706 33.9695C34.3203 34.0067 34.4509 33.9719 34.4913 33.9582L35.0353 33.4319C36.2022 32.303 37.9952 32.0919 39.4399 32.8561L41.9871 34.2035C44.1681 35.3571 44.7193 38.2419 42.9322 39.9708L41.0383 41.8032C40.4416 42.3804 39.6391 42.8619 38.6597 42.9507C36.2479 43.1696 30.624 42.8904 24.715 37.1733C19.1981 31.8358 18.1395 27.1812 18.0055 24.8879C17.9378 23.7282 18.501 22.7471 19.2173 22.054L21.31 20.0294ZM25.3633 21.6711C24.6879 20.7932 23.4289 20.7234 22.7201 21.4093L20.6274 23.4339C20.1876 23.8595 19.9759 24.3285 20.0022 24.7775C20.1086 26.5996 20.9636 30.7997 26.1251 35.7934C31.54 41.0323 36.5412 41.1886 38.4741 41.0133C38.869 40.9774 39.2618 40.7778 39.6283 40.4233L41.5222 38.5909C42.2921 37.8461 42.1223 36.4898 41.0319 35.913L38.4848 34.5658C37.7814 34.1938 36.9573 34.3165 36.4453 34.8118L35.8382 35.3993L35.1332 34.7093C35.8382 35.3993 35.8372 35.4002 35.8363 35.4011L35.8343 35.4029L35.8302 35.4068L35.8215 35.415L35.8021 35.4327C35.7881 35.4451 35.7719 35.4587 35.7539 35.4734C35.7177 35.5026 35.6731 35.5355 35.6199 35.5703C35.5131 35.6398 35.3722 35.7156 35.195 35.7797C34.834 35.9106 34.3578 35.9809 33.7674 35.8928C32.6117 35.7204 31.0809 34.9537 29.0451 32.9841C27.0099 31.0151 26.2153 29.5323 26.0364 28.4098C25.9448 27.8358 26.0178 27.3723 26.154 27.0207C26.2207 26.8484 26.2994 26.7113 26.3715 26.6077C26.4075 26.556 26.4418 26.5128 26.472 26.4777C26.4871 26.4601 26.5013 26.4446 26.5141 26.431L26.5324 26.4121L26.5408 26.4037L26.5448 26.3998L26.5467 26.3979C26.5477 26.397 26.5486 26.3961 27.2537 27.086L26.5486 26.3961L26.931 26.0261C27.5025 25.4732 27.5825 24.5555 27.0445 23.8563L25.3633 21.6711Z" fill="#C9C9C9" />
                     </svg>
                  </AdvatagesCardItemIcon>
                  <AdvatagesCardItemTitle className="white">
                     Живой колл-центр
                  </AdvatagesCardItemTitle>
                  <CustomText className="gray small">
                     Масштабированный технологиями, обеспечивает обход спам-фильтров операторов и дозванивается
                     до 90% контактов
                  </CustomText>
               </AdvatagesCardItem>
            </AdvatagesCard>
            {!isShowSlider &&
               <div className="icon_line">
                  <svg width="1222" height="140" viewBox="0 0 1222 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1221 0V18.7692C1221 29.8149 1212.05 38.7692 1201 38.7692H824M611 140V58.7692C611 47.7235 619.954 38.7692 631 38.7692H824M824 38.7692V0.538462" stroke="#353535" strokeWidth="2" strokeDasharray="6 6" />
                     <path d="M1 0V18.7692C1 29.8149 9.95431 38.7692 21 38.7692H394M611 140V58.7692C611 47.7235 602.046 38.7692 591 38.7692H394M394 38.7692V0" stroke="#353535" strokeWidth="2" strokeDasharray="6 6" />
                  </svg>
               </div>
            }
         </div>

      </section>
   );
}

export default Arsenal;