import { useEffect } from 'react';

const InvisibleRecaptcha = ({ onVerify }) => {

   useEffect(() => {
      const handleRecaptcha = () => {
         if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
               window.grecaptcha.execute('6LfhU34qAAAAAPywrdFvTHcjgfumJCSgcsCXyoIM', { action: 'submit' }).then((token) => {
                  onVerify(token);
               });
            });
         } else {
            console.log('grecaptcha is not available yet');
         }
      };

      const interval = setInterval(() => {
         if (window.grecaptcha) {
            clearInterval(interval);
            handleRecaptcha();
         }
      }, 100);

      return () => {
         clearInterval(interval);
      };
   }, [onVerify]);

   return null;
};

export default InvisibleRecaptcha;