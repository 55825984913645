import { useEffect, useRef } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from 'react-responsive'

gsap.registerPlugin(ScrollTrigger);

function PartnerPortraitHead() {
    const textRef = useRef(null);
    const scrollTriggerRef = useRef(null)
    const scrollTriggerOverlayRef = useRef(null)
    const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });

    useEffect(() => {
        if (!isTablet) {
            if (textRef.current && textRef.current != null) {
                const thisText = textRef.current;
                const allSvg = thisText.querySelectorAll("span svg");
                const thisLine = thisText.querySelector(".line");
                const thisOverlay = thisText.querySelector(".overlay");

                const svgTimeline = gsap.timeline({});

                allSvg.forEach((item, index) => {
                    svgTimeline.to(item, {
                        delay: 0,
                        duration: 0.6, // Длительность анимации для каждого item
                        onStart: () => {
                            item.classList.add("active");
                        }
                    });
                });

                svgTimeline.to(thisLine, {
                    delay: .2,
                    duration: 0.8,
                    onStart: () => {
                        thisLine.classList.add("active");
                    }
                });

                svgTimeline.pause()

                scrollTriggerRef.current = ScrollTrigger.create({
                    trigger: thisText,
                    start: "bottom bottom",
                    onEnter: () => {
                        svgTimeline.play();
                    }
                });

                let animation = gsap.to(thisOverlay, { yPercent: 90 })

                scrollTriggerOverlayRef.current = ScrollTrigger.create({
                    trigger: thisText,
                    start: "top bottom",
                    // end: 'top top',
                    scrub: .7,
                    once: false,
                    animation: animation
                });
            }
        } else {
            if (scrollTriggerRef.current && scrollTriggerRef.current != null) {
                scrollTriggerRef.current.kill()
            }
            if (scrollTriggerOverlayRef.current && scrollTriggerOverlayRef.current != null) {
                scrollTriggerOverlayRef.current.kill()
            }
        }
        if (scrollTriggerRef.current && scrollTriggerRef.current != null) {

            scrollTriggerRef.current.refresh(true)
            scrollTriggerRef.current.update()
        }
        if (scrollTriggerOverlayRef.current && scrollTriggerOverlayRef.current != null) {

            scrollTriggerOverlayRef.current.refresh(true)
            scrollTriggerOverlayRef.current.update()
        }
    }, [isTablet]);
    return (
        !isTablet ? (
            <div className="partner_portrait_wrap-head section_title" ref={textRef}>
                Цель всех инструментов digital-маркетинга, от&nbsp;SEO до&nbsp;Я.
                Директа, одна&nbsp;-
                <span>
                    &nbsp;
                    генерация обращений,
                    <svg width="462" height="68" viewBox="0 0 462 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M460.5 30C460.5 31.7969 459.095 33.6887 456.058 35.6244C453.05 37.5419 448.581 39.41 442.778 41.1996C431.18 44.7764 414.371 48.0043 393.57 50.7174C351.975 56.1428 294.498 59.5 231 59.5C167.502 59.5 110.025 56.1428 68.4301 50.7174C47.6293 48.0043 30.8205 44.7764 19.2219 41.1996C13.419 39.41 8.94979 37.5419 5.94154 35.6244C2.90478 33.6887 1.5 31.7969 1.5 30C1.5 28.2031 2.90478 26.3113 5.94154 24.3756C8.94979 22.4581 13.419 20.59 19.2219 18.8004C30.8205 15.2236 47.6293 11.9957 68.4301 9.2826C110.025 3.85724 167.502 0.5 231 0.5C294.498 0.5 351.975 3.85724 393.57 9.2826C414.371 11.9957 431.18 15.2236 442.778 18.8004C448.581 20.59 453.05 22.4581 456.058 24.3756C459.095 26.3113 460.5 28.2031 460.5 30Z"
                            stroke="#B3B3B3" className="svg-elem-1"></path>
                        <path d="M460.86 21.9736C461.438 38.532 358.995 55.5491 232.047 59.9822C105.099 64.4153 1.71861 54.5858 1.14038 38.0273" stroke="#B3B3B3"
                            className="svg-elem-2"></path>
                    </svg>
                    &nbsp;
                </span>
                так как
                бизнес ждет лиды и&nbsp;просит прогнозы по&nbsp;их&nbsp;приросту.
                <span className='line'>
                    Имплементация идентификации скрытых пользователей
                    <div className="line-line"></div>
                </span>
                <span>
                    &nbsp;
                    в продуктовый портфель
                    <svg width="462" height="68" viewBox="0 0 462 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M460.5 30C460.5 31.7969 459.095 33.6887 456.058 35.6244C453.05 37.5419 448.581 39.41 442.778 41.1996C431.18 44.7764 414.371 48.0043 393.57 50.7174C351.975 56.1428 294.498 59.5 231 59.5C167.502 59.5 110.025 56.1428 68.4301 50.7174C47.6293 48.0043 30.8205 44.7764 19.2219 41.1996C13.419 39.41 8.94979 37.5419 5.94154 35.6244C2.90478 33.6887 1.5 31.7969 1.5 30C1.5 28.2031 2.90478 26.3113 5.94154 24.3756C8.94979 22.4581 13.419 20.59 19.2219 18.8004C30.8205 15.2236 47.6293 11.9957 68.4301 9.2826C110.025 3.85724 167.502 0.5 231 0.5C294.498 0.5 351.975 3.85724 393.57 9.2826C414.371 11.9957 431.18 15.2236 442.778 18.8004C448.581 20.59 453.05 22.4581 456.058 24.3756C459.095 26.3113 460.5 28.2031 460.5 30Z"
                            stroke="#B3B3B3" className="svg-elem-1"></path>
                        <path d="M460.86 21.9736C461.438 38.532 358.995 55.5491 232.047 59.9822C105.099 64.4153 1.71861 54.5858 1.14038 38.0273" stroke="#B3B3B3"
                            className="svg-elem-2"></path>
                    </svg>
                    &nbsp;
                </span> партнера, вдвое увеличивает
                количество квалифицированных лидов, которые
                партнер генерирует своим клиентам через
                традиционные инструменты.
                <div className="overlay"></div>
            </div>
        ) : (
            <div className="partner_portrait_wrap-head section_title">
                Цель всех инструментов digital-маркетинга, от&nbsp;SEO до&nbsp;Я.
                Директа, одна&nbsp;-
                <span>
                    &nbsp;
                    генерация обращений,
                    <svg width="462" height="68" viewBox="0 0 462 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M460.5 30C460.5 31.7969 459.095 33.6887 456.058 35.6244C453.05 37.5419 448.581 39.41 442.778 41.1996C431.18 44.7764 414.371 48.0043 393.57 50.7174C351.975 56.1428 294.498 59.5 231 59.5C167.502 59.5 110.025 56.1428 68.4301 50.7174C47.6293 48.0043 30.8205 44.7764 19.2219 41.1996C13.419 39.41 8.94979 37.5419 5.94154 35.6244C2.90478 33.6887 1.5 31.7969 1.5 30C1.5 28.2031 2.90478 26.3113 5.94154 24.3756C8.94979 22.4581 13.419 20.59 19.2219 18.8004C30.8205 15.2236 47.6293 11.9957 68.4301 9.2826C110.025 3.85724 167.502 0.5 231 0.5C294.498 0.5 351.975 3.85724 393.57 9.2826C414.371 11.9957 431.18 15.2236 442.778 18.8004C448.581 20.59 453.05 22.4581 456.058 24.3756C459.095 26.3113 460.5 28.2031 460.5 30Z"
                            stroke="#B3B3B3" className="svg-elem-1"></path>
                        <path d="M460.86 21.9736C461.438 38.532 358.995 55.5491 232.047 59.9822C105.099 64.4153 1.71861 54.5858 1.14038 38.0273" stroke="#B3B3B3"
                            className="svg-elem-2"></path>
                    </svg>
                    &nbsp;
                </span>
                так как
                бизнес ждет лиды и&nbsp;просит прогнозы по&nbsp;их&nbsp;приросту.
                Имплементация идентификации скрытых пользователей
                <span>
                    &nbsp;
                    в&nbsp;продуктовый портфель
                    <svg width="462" height="68" viewBox="0 0 462 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M460.5 30C460.5 31.7969 459.095 33.6887 456.058 35.6244C453.05 37.5419 448.581 39.41 442.778 41.1996C431.18 44.7764 414.371 48.0043 393.57 50.7174C351.975 56.1428 294.498 59.5 231 59.5C167.502 59.5 110.025 56.1428 68.4301 50.7174C47.6293 48.0043 30.8205 44.7764 19.2219 41.1996C13.419 39.41 8.94979 37.5419 5.94154 35.6244C2.90478 33.6887 1.5 31.7969 1.5 30C1.5 28.2031 2.90478 26.3113 5.94154 24.3756C8.94979 22.4581 13.419 20.59 19.2219 18.8004C30.8205 15.2236 47.6293 11.9957 68.4301 9.2826C110.025 3.85724 167.502 0.5 231 0.5C294.498 0.5 351.975 3.85724 393.57 9.2826C414.371 11.9957 431.18 15.2236 442.778 18.8004C448.581 20.59 453.05 22.4581 456.058 24.3756C459.095 26.3113 460.5 28.2031 460.5 30Z"
                            stroke="#B3B3B3" className="svg-elem-1"></path>
                        <path d="M460.86 21.9736C461.438 38.532 358.995 55.5491 232.047 59.9822C105.099 64.4153 1.71861 54.5858 1.14038 38.0273" stroke="#B3B3B3"
                            className="svg-elem-2"></path>
                    </svg>
                    &nbsp;
                </span> партнера, вдвое увеличивает
                количество квалифицированных лидов, которые
                партнер генерирует своим клиентам через
                традиционные инструменты.
                <div className="overlay"></div>
            </div>
        )
    );
}

export default PartnerPortraitHead;