function Loader() {
   return (
      <div className="loader">
         <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <path className='path1' d="M32.7642 2V8.86412" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path2' d="M47.8203 15.1005L52.674 10.2468" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path3' d="M62.0003 32.7628H55.1362" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path4' d="M48.8984 47.8198L53.7521 52.6735" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path5' d="M32.7642 55.1359V62" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path6' d="M10.2476 52.6724L15.1012 47.8187" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path7' d="M8.86412 32.7628H2" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path className='path8' d="M11.3257 10.2469L16.1793 15.1006" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
         </svg>
      </div>
   );
}

export default Loader;