import React, { useState } from 'react';
import './form_block.scss';
import { PatternFormat } from 'react-number-format';
import RecaptchaScript from '../RecaptchaScript/RecaptchaScript';
import InvisibleRecaptcha from '../RecaptchaScript/InvisibleRecaptcha';
import { Link } from 'react-router-dom';



const Form_block = ({ setModalType }) => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        site: ''
    });
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handlePhoneChange = (values) => {
        const { formattedValue } = values; // Получаем форматированное значение
        setFormData({
            ...formData,
            phone: formattedValue,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!recaptchaToken) {
            console.log('reCAPTCHA token is missing');
            return;
        }

        setModalType('loading');

        fetch('/send.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...formData, recaptchaToken }),
        })
            .then((response) => response.json()) // Преобразуем ответ в JSON
            .then((data) => {
                if (data.Result === "Success") {
                    setModalType('success');
                } else {
                    setModalType('error');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setModalType('error');
            });
    };

    return (
        <>
            <RecaptchaScript />
            <form onSubmit={handleSubmit}>
                <div className={'item'}>
                    <input
                        type={'text'}
                        value={formData.name}
                        required={true}
                        name={'name'}
                        placeholder={'Ваше имя*'}
                        onChange={handleChange}
                    />
                </div>
                <div className={'item'}>
                    <PatternFormat
                        name='phone'
                        format="+7##########"
                        mask="_"
                        placeholder="Ваш телефон*"
                        required
                        value={formData.phone}
                        onValueChange={handlePhoneChange}
                    />
                </div>
                <div className={'item'}>
                    <input
                        type={'text'}
                        value={formData.site}
                        required={false}
                        name={'site'}
                        placeholder={'Сайт'}
                        onChange={handleChange}
                    />
                </div>
                <div className="modal_check">
                    <input
                        type="checkbox"
                        required={true}
                        className="custom-checkbox"
                        id="get_touch-check"
                        name='consent'
                    />
                    <label htmlFor="get_touch-check">Я согласен на обработку <Link to="/privacy">персональных данных*</Link></label>
                </div>
                <div className="btn_sub">
                    <input type={'submit'} value={'отправить'} className={'btn_sub-btn'} />
                </div>
                <InvisibleRecaptcha onVerify={setRecaptchaToken} />
            </form>
        </>
    );
};

export default Form_block;