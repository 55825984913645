import React, { useState, useEffect, useRef, useCallback } from 'react'
import CustomText from '../../utilities/CustomText/CustomText';
import './ServicePageTariffs.scss'
import { useMediaQuery } from 'react-responsive'

function ServicePageTariffs() {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
   const [lineClamp, setLineClamp] = useState(isMobile ? '7' : '8');
   const tariffsRef = useRef(null);
   const [heights, setHeights] = useState([]);
   const prevWidthRef = useRef(window.innerWidth); // Сохраняем предыдущую ширину экрана

   const handleClick = (e) => {
      const thisTarget = e.target;
      const thisParent = thisTarget.closest('.tariffs_block_item_list_it');
      const textElement = thisParent.querySelector('.tariffs_block_item_list_it-text');
      const totalHeight = textElement.scrollHeight;
      const thisBtn = thisParent.querySelector('.tariffs_block_item_list_it-btn');
      const thisBtnIndex = thisBtn.getAttribute('data-btn-id');

      // Задаем класс кнопке
      thisBtn.classList.toggle('active');

      // Выполняем действия в зависимости от класса на кнопке
      if (thisBtn.classList.contains('active')) {
         textElement.style.maxHeight = `${totalHeight}px`;
         thisBtn.querySelector('span').textContent = 'Скрыть';
      } else {
         textElement.style.maxHeight = `${heights[thisBtnIndex]}px`;
         thisBtn.querySelector('span').textContent = 'Больше';
      }
   };

   const setMaxHeight = useCallback(() => {
      if (tariffsRef.current) {
         // Получаеи массив все тарифов
         const thisTariff = Array.from(tariffsRef.current.children);
         const newHeights = thisTariff.map((item, index) => {
            // Получаем текст элемента
            const thisText = item.querySelector('.tariffs_block_item_list_it-text');
            // Убираем длительность анимации и высоту
            thisText.style.transition = 'unset';
            thisText.style.maxHeight = 'unset';
            thisText.style.display = '-webkit-box';
            // Получаем всю высоту
            const thisTextheight = thisText.scrollHeight;
            // задаем ограничение по строчка
            thisText.style.webkitLineClamp = lineClamp;
            // Получаем высоту с ограничением по строчкам
            const thisTextClampHeight = thisText.clientHeight;
            // Убираем ограничение по строкам
            thisText.style.webkitLineClamp = '';
            // Задаем высоту по ограничению
            thisText.style.maxHeight = `${thisTextClampHeight}px`;
            thisText.style.display = 'block';
            // Вовзращаем длительность анимации
            thisText.style.transition = '.4s';

            if (thisTextClampHeight < thisTextheight) {
               item.querySelector('.tariffs_block_item_list_it-btn').style.display = 'inline-flex';
            } else {
               item.querySelector('.tariffs_block_item_list_it-btn').style.display = 'none';
            }

            // Вовзвращаем ограниченную высоту
            return thisTextClampHeight;
         });

         // Убираем при ресайзе активные классы у кнопок и меняем текст
         thisTariff.forEach(item => {
            const thisBtn = item.querySelector('.tariffs_block_item_list_it-btn')
            thisBtn.classList.remove('active')
            thisBtn.querySelector('span').textContent = 'Больше';
         })

         // Собираем массив со значением высот
         setHeights(newHeights);
      }
   }, [lineClamp]);

   useEffect(() => {
      setMaxHeight();

      const handleResize = () => {
         const currentWidth = window.innerWidth;
         if (currentWidth !== prevWidthRef.current) { // Проверяем, изменилась ли ширина
            const newIsMobile = currentWidth <= 767;
            setLineClamp(newIsMobile ? '7' : '10');
            setMaxHeight();
            prevWidthRef.current = currentWidth; // Обновляем предыдущую ширину
         }
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, [setMaxHeight]);

   return (
      <div className="tariffs_block">
         <div className="tariffs_block_item">
            <div className="tariffs_block_item-title">
               Для всех проектов предусмотрена ежемесячная абонентская плата
            </div>
            <div className="tariffs_block_item_list row" ref={tariffsRef}>
               <div className="col-md-6">
                  <div className="tariffs_block_item_list_it">
                     <div className="tariffs_block_item_list_it-icon">
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                           <path d="M31.0042 30C32.4597 30 33.7004 28.9572 33.9538 27.5205C34.0926 26.7312 33.9163 25.9354 33.4566 25.2798C32.9975 24.6234 32.3101 24.1854 31.5206 24.046C31.3457 24.0155 31.1687 24 30.9958 24C29.5403 24 28.2996 25.0425 28.0462 26.4792C27.9074 27.268 28.0837 28.0638 28.5434 28.7202C29.0028 29.3763 29.6904 29.8146 30.4794 29.9537C30.6535 29.9845 30.8301 30 31.0042 30Z" fill="#006AFE" />
                           <path d="M27.0042 38C28.4597 38 29.7004 36.9572 29.9538 35.5205C30.0926 34.7312 29.9163 33.9354 29.4566 33.2798C28.9975 32.6234 28.3101 32.1854 27.5206 32.046C27.3457 32.0155 27.1687 32 26.9958 32C25.5403 32 24.2996 33.0425 24.0462 34.4792C23.9074 35.268 24.0837 36.0638 24.5434 36.7202C25.0028 37.3763 25.6904 37.8146 26.4794 37.9537C26.6535 37.9845 26.8301 38 27.0042 38Z" fill="#006AFE" />
                           <path d="M35.0042 38C36.4597 38 37.7004 36.9572 37.9538 35.5205C38.0926 34.7312 37.9163 33.9354 37.4566 33.2798C36.9975 32.6234 36.3101 32.1854 35.5206 32.046C35.3457 32.0155 35.1687 32 34.9958 32C33.5403 32 32.2996 33.0425 32.0462 34.4792C31.9074 35.268 32.0837 36.0638 32.5434 36.7202C33.0028 37.3763 33.6904 37.8146 34.4794 37.9537C34.6535 37.9845 34.8301 38 35.0042 38Z" fill="#006AFE" />
                        </svg>
                     </div>
                     <h3 className="tariffs_block_item_list_it-title">
                        Пакет «TECH»
                     </h3>
                     <CustomText className='tariffs_block_item_list_it-text small'>
                        <ul>
                           <li>
                              Создание архитектуры проекта и интеграция с БД платформы
                           </li>
                           <li>
                              Разметка источников трафика
                           </li>
                           <li>
                              Проведение интеграции с системой рекламодателя (передача данных, идентификация контактов CRM среди посетителей и проброс заявки в CRM)
                           </li>
                           <li>
                              Возможность сортировки и фильтрации базы (GEO, MD5)
                           </li>
                           <li>
                              Отображение источников данных в зашифрованном виде
                           </li>
                           <li>
                              Выделенный менеджер по сопровождению (мониторинг и контроль выполнения отгрузки)
                           </li>
                        </ul>
                        <p>
                           Доступ в ЛК:
                        </p>
                        <ol>
                           <li>
                              Статистика по проекту, параметры - посетители, определения
                           </li>
                           <li>
                              Отчет по идентифицированным номерам (номер телефона, GEO, MD5, дата и время посещения)
                           </li>
                        </ol>
                     </CustomText>
                     <div className="tariffs_block_item_list_it-cost">
                        4 990 руб/месяц
                     </div>
                     <button type='button' className='tariffs_block_item_list_it-btn' data-btn-id="0" onClick={handleClick}>
                        <span>БОЛЬШЕ</span>
                        <div className="icon">
                           <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 7H14" stroke="#121212" />
                              <path className='hide' d="M7 14L7 2.68221e-07" stroke="#121212" />
                           </svg>
                        </div>
                     </button>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="tariffs_block_item_list_it">
                     <div className="tariffs_block_item_list_it-icon">
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                           <path d="M27.0042 30C28.4597 30 29.7004 28.9572 29.9538 27.5205C30.0926 26.7312 29.9163 25.9354 29.4566 25.2798C28.9975 24.6234 28.3101 24.1854 27.5206 24.046C27.3457 24.0155 27.1687 24 26.9958 24C25.5403 24 24.2996 25.0425 24.0462 26.4792C23.9074 27.268 24.0837 28.0638 24.5434 28.7202C25.0028 29.3763 25.6904 29.8146 26.4794 29.9537C26.6535 29.9845 26.8301 30 27.0042 30Z" fill="#006AFE" />
                           <path d="M35.0042 30C36.4597 30 37.7004 28.9572 37.9538 27.5205C38.0926 26.7312 37.9163 25.9354 37.4566 25.2798C36.9975 24.6234 36.3101 24.1854 35.5206 24.046C35.3457 24.0155 35.1687 24 34.9958 24C33.5403 24 32.2996 25.0425 32.0462 26.4792C31.9074 27.268 32.0837 28.0638 32.5434 28.7202C33.0028 29.3763 33.6904 29.8146 34.4794 29.9537C34.6535 29.9845 34.8301 30 35.0042 30Z" fill="#006AFE" />
                           <path d="M27.0042 38C28.4597 38 29.7004 36.9572 29.9538 35.5205C30.0926 34.7312 29.9163 33.9354 29.4566 33.2798C28.9975 32.6234 28.3101 32.1854 27.5206 32.046C27.3457 32.0155 27.1687 32 26.9958 32C25.5403 32 24.2996 33.0425 24.0462 34.4792C23.9074 35.268 24.0837 36.0638 24.5434 36.7202C25.0028 37.3763 25.6904 37.8146 26.4794 37.9537C26.6535 37.9845 26.8301 38 27.0042 38Z" fill="#006AFE" />
                           <path d="M35.0042 38C36.4597 38 37.7004 36.9572 37.9538 35.5205C38.0926 34.7312 37.9163 33.9354 37.4566 33.2798C36.9975 32.6234 36.3101 32.1854 35.5206 32.046C35.3457 32.0155 35.1687 32 34.9958 32C33.5403 32 32.2996 33.0425 32.0462 34.4792C31.9074 35.268 32.0837 36.0638 32.5434 36.7202C33.0028 37.3763 33.6904 37.8146 34.4794 37.9537C34.6535 37.9845 34.8301 38 35.0042 38Z" fill="#006AFE" />
                        </svg>
                     </div>
                     <h3 className="tariffs_block_item_list_it-title">
                        Пакет «CALL»
                     </h3>
                     <CustomText className='tariffs_block_item_list_it-text small'>
                        <ul>
                           <li>Выделение обезличенных телефонных номеров для исходящих линий, которые не помечаются как «СПАМ»</li>
                           <li>Резервирование исходящих линий под объемы Заказчика</li>
                           <li>Разработка и корректировка скрипта</li>
                           <li>Предоставление отчетности</li>
                           <li>Настройка отправки уведомлений на необходимые email (статус о переведенном лиде на Заказчика)</li>
                           <li>Возможность оперативного распределения/смены маршрутизации лидов по точкам продаж Заказчика</li>
                           <li>Проведение интеграции с системой рекламодателя (передача данных, идентификация контактов CRM среди посетителей и проброс заявки в CRM)</li>
                           <li>Закрепление за рекламной кампанией менеджера по сопровождению с графиком доступа 5/2, 8 ч.</li>
                           <li>Закрепление куратора группы операторов</li>
                           <li>Настройка графика обзвона по времени - час/день и по количеству звонков в день/в час</li>
                        </ul>
                        <p>
                           Доступ в ЛК:
                        </p>
                        <ol>
                           <li>
                              Статистика по проекту, параметры – звонки, лиды, конверсия
                           </li>
                           <li>
                              Отчет по звонкам (номер с которого звонили, номер кому звонили,  дата и время, записи разговоров)
                           </li>
                        </ol>
                     </CustomText>
                     <div className="tariffs_block_item_list_it-cost">
                        4 990 руб/месяц
                     </div>
                     <button type='button' className='tariffs_block_item_list_it-btn' data-btn-id="1" onClick={handleClick}>
                        <span>БОЛЬШЕ</span>
                        <div className="icon">
                           <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 7H14" stroke="#121212" />
                              <path className='hide' d="M7 14L7 2.68221e-07" stroke="#121212" />
                           </svg>
                        </div>
                     </button>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="tariffs_block_item_list_it">
                     <div className="tariffs_block_item_list_it-icon">
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                           <path d="M27.0042 30C28.4597 30 29.7004 28.9572 29.9538 27.5205C30.0926 26.7312 29.9163 25.9354 29.4566 25.2798C28.9975 24.6234 28.3101 24.1854 27.5206 24.046C27.3457 24.0155 27.1687 24 26.9958 24C25.5403 24 24.2996 25.0425 24.0462 26.4792C23.9074 27.268 24.0837 28.0638 24.5434 28.7202C25.0028 29.3763 25.6904 29.8146 26.4794 29.9537C26.6535 29.9845 26.8301 30 27.0042 30Z" fill="#006AFE" />
                           <path d="M35.0042 30C36.4597 30 37.7004 28.9572 37.9538 27.5205C38.0926 26.7312 37.9163 25.9354 37.4566 25.2798C36.9975 24.6234 36.3101 24.1854 35.5206 24.046C35.3457 24.0155 35.1687 24 34.9958 24C33.5403 24 32.2996 25.0425 32.0462 26.4792C31.9074 27.268 32.0837 28.0638 32.5434 28.7202C33.0028 29.3763 33.6904 29.8146 34.4794 29.9537C34.6535 29.9845 34.8301 30 35.0042 30Z" fill="#006AFE" />
                           <path d="M23.0042 38C24.4597 38 25.7004 36.9572 25.9538 35.5205C26.0926 34.7312 25.9163 33.9354 25.4566 33.2798C24.9975 32.6234 24.3101 32.1854 23.5206 32.046C23.3457 32.0155 23.1687 32 22.9958 32C21.5403 32 20.2996 33.0425 20.0462 34.4792C19.9074 35.268 20.0837 36.0638 20.5434 36.7202C21.0028 37.3763 21.6904 37.8146 22.4794 37.9537C22.6535 37.9845 22.8301 38 23.0042 38Z" fill="#006AFE" />
                           <path d="M39.0042 38C40.4597 38 41.7004 36.9572 41.9538 35.5205C42.0926 34.7312 41.9163 33.9354 41.4566 33.2798C40.9975 32.6234 40.3101 32.1854 39.5206 32.046C39.3457 32.0155 39.1687 32 38.9958 32C37.5403 32 36.2996 33.0425 36.0462 34.4792C35.9074 35.268 36.0837 36.0638 36.5434 36.7202C37.0028 37.3763 37.6904 37.8146 38.4794 37.9537C38.6535 37.9845 38.8301 38 39.0042 38Z" fill="#006AFE" />
                           <path d="M31.0042 38C32.4597 38 33.7004 36.9572 33.9538 35.5205C34.0926 34.7312 33.9163 33.9354 33.4566 33.2798C32.9975 32.6234 32.3101 32.1854 31.5206 32.046C31.3457 32.0155 31.1687 32 30.9958 32C29.5403 32 28.2996 33.0425 28.0462 34.4792C27.9074 35.268 28.0837 36.0638 28.5434 36.7202C29.0028 37.3763 29.6904 37.8146 30.4794 37.9537C30.6535 37.9845 30.8301 38 31.0042 38Z" fill="#006AFE" />
                        </svg>
                     </div>
                     <h3 className="tariffs_block_item_list_it-title">
                        Пакет «MUST»
                     </h3>
                     <CustomText className='tariffs_block_item_list_it-text small'>
                        <ul>
                           <li>Выделение обезличенных телефонных номеров для исходящих линий, которые не помечаются как «СПАМ»</li>
                           <li>Резервирование исходящих линий под объемы Заказчика</li>
                           <li>Подготовка медиаплана по расчету объема лидов на месяц/квартал (в зависимости от количества трафика на сайте) c еженедельным мониторингом выполнения для контролирования отгрузки</li>
                           <li>Разработка и корректировка скрипта и/или контента рассылки</li>
                           <li>Настройка отправки уведомлений на необходимые email (статус о переведенном лиде на Заказчика)</li>
                           <li>Возможность оперативного распределения/смены маршрутизации лидов по точкам продаж Заказчика</li>
                           <li>Сверка лидов через личный кабинет Reffection</li>
                           <li>Выделение куратора группы операторов</li>
                           <li>Выделение одного имени отправителя для SMS рассылки</li>
                           <li>Настройка графика отгрузки лидов по времени - час/день и по количеству лидов в день/в час</li>
                           <li>Настройка правила «времени взятия в работу номера» после его определения</li>
                           <li>Возможность сортировки базы по GEO, MD5</li>
                           <li>Закрепление за рекламной кампанией менеджера по сопровождению с графиком доступа 5/2, 8 ч.</li>
                        </ul>
                        <p>
                           Доступ в личный кабинет Reffection с возможностями получать:
                        </p>
                        <ol>
                           <li>
                              статистику по проекту: посетители, определения, лиды, конверсии
                           </li>
                           <li>
                              отчеты по проекту, информация о лидах: номер телефона, дата, время, длительность + запись разговоров
                           </li>
                        </ol>
                     </CustomText>
                     <div className="tariffs_block_item_list_it-cost">
                        9 900 руб/месяц
                     </div>
                     <button type='button' className='tariffs_block_item_list_it-btn' data-btn-id="2" onClick={handleClick}>
                        <span>БОЛЬШЕ</span>
                        <div className="icon">
                           <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 7H14" stroke="#121212" />
                              <path className='hide' d="M7 14L7 2.68221e-07" stroke="#121212" />
                           </svg>
                        </div>
                     </button>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="tariffs_block_item_list_it priority">
                     <div className="tariffs_block_item_list_it-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" fill="none">
                           <rect x="1" y="1" width="60" height="60" rx="12" stroke="#006AFE" />
                           <path d="M35.9867 25.6923C36.3307 25.9814 36.7676 26.1404 37.2159 26.1404C37.7848 26.1404 38.3202 25.8909 38.6859 25.4559C39.365 24.6466 39.2589 23.4355 38.4497 22.7559C38.1052 22.4668 37.6682 22.3076 37.2194 22.3076C36.6508 22.3076 36.1154 22.5571 35.7502 22.9921C35.0709 23.8017 35.1772 25.0125 35.9867 25.6923Z" fill="#006AFE" />
                           <path d="M40.511 27.8857C40.3922 27.8857 40.2721 27.8963 40.1547 27.917C39.0512 28.1116 38.3111 29.1684 38.5057 30.2725C38.6769 31.2456 39.5172 31.9518 40.5034 31.9518C40.622 31.9518 40.7425 31.9412 40.8604 31.9208C41.964 31.7259 42.7038 30.6693 42.5092 29.5658C42.3375 28.5924 41.4969 27.8857 40.511 27.8857Z" fill="#006AFE" />
                           <path d="M40.4398 34.5335C40.113 34.3445 39.7425 34.2451 39.3685 34.2451C38.6013 34.2451 37.8867 34.6573 37.5036 35.3206C37.217 35.8174 37.1402 36.3961 37.2891 36.9507C37.4379 37.5053 37.794 37.9691 38.2903 38.2558C38.6173 38.445 38.9878 38.5446 39.3623 38.5446C40.129 38.5446 40.8436 38.1328 41.2264 37.4696C41.8184 36.4431 41.4656 35.126 40.4398 34.5335Z" fill="#006AFE" />
                           <path d="M34.3268 38.3555C34.0635 38.3555 33.8027 38.4017 33.5517 38.4928C32.9833 38.6997 32.5298 39.1158 32.2739 39.6642C32.0183 40.2127 31.9915 40.8278 32.1982 41.3964C32.523 42.2886 33.3787 42.8883 34.3275 42.8883C34.5908 42.8883 34.8516 42.842 35.1018 42.7507C35.6697 42.5438 36.123 42.128 36.3791 41.5785C36.635 41.0298 36.6621 40.4147 36.4559 39.847C36.1316 38.9551 35.2755 38.3555 34.3268 38.3555Z" fill="#006AFE" />
                           <path d="M28.5671 38.3825C28.3029 38.2865 28.0288 38.2378 27.7527 38.2378C26.7552 38.2378 25.8556 38.8682 25.5144 39.8064C25.2964 40.4045 25.3246 41.0514 25.5936 41.6279C25.8623 42.2047 26.3394 42.6419 26.9375 42.8597C27.2013 42.9559 27.4756 43.0041 27.7524 43.0041C28.7504 43.0041 29.6498 42.3737 29.9908 41.4362C30.2083 40.8393 30.1798 40.1924 29.9106 39.6154C29.6412 39.0377 29.1643 38.6 28.5671 38.3825Z" fill="#006AFE" />
                           <path d="M24.8786 35.145C24.4332 34.3741 23.6033 33.8955 22.7121 33.8955C22.2763 33.8955 21.8455 34.0116 21.4653 34.2304C20.8871 34.5643 20.4737 35.1036 20.3012 35.7475C20.1283 36.3922 20.2168 37.0657 20.5507 37.6436C20.9961 38.4148 21.8263 38.8936 22.7175 38.8936C23.153 38.8936 23.5838 38.7774 23.9637 38.5585C25.1566 37.8698 25.567 36.3383 24.8786 35.145Z" fill="#006AFE" />
                           <path d="M21.5767 32.535C22.8458 32.535 23.9277 31.6259 24.1487 30.3732C24.2697 29.685 24.116 28.9911 23.7151 28.4196C23.3148 27.8472 22.7154 27.4654 22.027 27.3438C21.8744 27.3172 21.7201 27.3037 21.5693 27.3037C20.3002 27.3037 19.2183 28.2126 18.9973 29.4653C18.8763 30.153 19.03 30.8469 19.4309 31.4192C19.8314 31.9913 20.4311 32.3734 21.119 32.4947C21.2709 32.5215 21.4249 32.535 21.5767 32.535Z" fill="#006AFE" />
                           <path d="M24.8629 26.9563C25.5024 26.9563 26.1254 26.7292 26.6173 26.317C27.1753 25.8488 27.5176 25.1901 27.5813 24.4623C27.645 23.7345 27.4221 23.0263 26.9536 22.4683C26.4334 21.8482 25.6699 21.4927 24.8594 21.4927C24.219 21.4927 23.596 21.7195 23.1048 22.1317C22.5461 22.6007 22.2031 23.2593 22.1396 23.9862C22.0759 24.7125 22.299 25.4209 22.7682 25.9802C23.2891 26.6005 24.0524 26.9563 24.8629 26.9563Z" fill="#006AFE" />
                           <path d="M31.0397 24.9466C32.6807 24.9466 34.011 23.6163 34.011 21.9752C34.011 20.3342 32.6807 19.0039 31.0397 19.0039C29.3987 19.0039 28.0684 20.3342 28.0684 21.9752C28.0684 23.6163 29.3987 24.9466 31.0397 24.9466Z" fill="#006AFE" />
                        </svg>
                     </div>
                     <h3 className="tariffs_block_item_list_it-title">
                        Пакет «PRO»
                     </h3>
                     <CustomText className='tariffs_block_item_list_it-text small'>
                        <ul>
                           <li>                           Все услуги, перечисленные
                              в пакете «MUST»</li>
                           <li>Сверка в согласованном с Заказчиком формате, обновление с периодичностью раз в неделю/чаще по запросу</li>
                           <li>Возможность сортировки базы по GEO, URL, UTM, Referer, Time</li>
                           <li>Открытое отображение источников данных</li>
                           <li>Выделение дополнительных имен отправителя для SMS рассылки</li>
                           <li>Реактивация ранее обработанного трафика со статусами: «Отказ», «Уточнить решение», «Не определился с Моделью» (до 3 мес.)</li>
                           <li>Подготовка аналитической справки за месяц с результатами детализации звонков + комментарии от колл-центра (основные пункты согласовываются отдельно)</li>
                        </ul>
                     </CustomText>
                     <div className="tariffs_block_item_list_it-cost">
                        14 990 руб/месяц
                     </div>
                     <button type='button' className='tariffs_block_item_list_it-btn' data-btn-id="3" onClick={handleClick}>
                        <span>БОЛЬШЕ</span>
                        <div className="icon">
                           <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 7H14" stroke="#121212" />
                              <path className='hide' d="M7 14L7 2.68221e-07" stroke="#121212" />
                           </svg>
                        </div>
                     </button>
                  </div>
               </div>
            </div>
            <CustomText className={`tariffs_block_item-text_bot gray`}>
               <p>
                  Стоимость пакетов абонентской платы варьируется от&nbsp;объема предоставляемых данных (в&nbsp;рамках выбранных
                  для внедрения продуктов) и&nbsp;перечня услуг по&nbsp;настройке, обучению и&nbsp;контролю за&nbsp;реализацией проектов
               </p>
            </CustomText>
         </div>
      </div>
   );
}

export default ServicePageTariffs;